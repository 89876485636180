// Angular Imports
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@app/app.module';
import { GlobalModule } from '@app/GlobalModule/GlobalModule.module';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { GridAllModule, GridModule } from '@syncfusion/ej2-angular-grids';
import { NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';

// This Module's Components
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComtableComponent } from './comtable.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        GlobalModule,
        CheckBoxModule,
        DropDownListAllModule,
        DatePickerAllModule,
        GridAllModule,
        NumericTextBoxAllModule,
        ToolbarModule,
        GridModule,
        TooltipAllModule,
        MatTooltipModule,
        ListViewModule,
        
    ],
    declarations: [
        
        ComtableComponent,
    ],
  bootstrap:[ComtableComponent],
    exports: [
        ComtableComponent,
    ],
    // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComTableModule {

}
