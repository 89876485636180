import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as myGlobals from '@shared/global_var';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    prescreen_apiUrl = `${myGlobals._apiPathv3}prescreen-forms`;
    TotalCountPrescreenPendingingReview:BehaviorSubject<number> = new BehaviorSubject(0);
    hideSideBar: BehaviorSubject<string> = new BehaviorSubject('');
    isOpenInteraction: BehaviorSubject<boolean> = new BehaviorSubject(true);

    constructor(
        public http: HttpClient,
        private _Global: GlobalfunctionService
    ) {}

   // script category .................

    scriptCategoryCreate(data: any): Observable<any> {
        return this.http.post(`${myGlobals._apiPathv3}script-repos/category/create`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    getscriptCategoryList(): Observable<any> {
        return this.http.get(`${myGlobals._apiPathv3}script-repos/category`, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    scriptCategoryUpdate(data: any,id:string): Observable<any> {
        return this.http.put(`${myGlobals._apiPathv3}script-repos/category/update/${id}`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    deleteScriptCategory(id:string): Observable<any> {
        return this.http.delete(`${myGlobals._apiPathv3}script-repos/category/delete/${id}`, {headers: this._Global.httpOptions }) as Observable<any>;
    }

   // Department .................

   DepartmentCreate(data: any): Observable<any> {
    return this.http.post(`${myGlobals._apiPathv3}mass-com/create-department`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    getDepartmentList(): Observable<any> {
        return this.http.get(`${myGlobals._apiPathv3}mass-com/list-department`, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    // DepartmentUpdate(data: any,id:string): Observable<any> {
    //     return this.http.put(`${myGlobals._apiPathv3}mass-com/update-department/${id}`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    // }
    DepartmentUpdate(data: any): Observable<any> {
        return this.http.post(`${myGlobals._apiPathv3}mass-com/update-department`, data, {headers: this._Global.httpOptions }) as Observable<any>;
     }
    // deleteDepartment(id:string): Observable<any> {
    //     return this.http.delete(`${myGlobals._apiPathv3}v1/department/delete/${id}`, {headers: this._Global.httpOptions }) as Observable<any>;
    // }
    deleteDepartment(data: any): Observable<any> {
        return this.http.post(`${myGlobals._apiPathv3}mass-com/delete-department`, data, {headers: this._Global.httpOptions }) as Observable<any>;
        }

    // Prescreen Category .................

    PrescreenFormCategoryCreate(data: any): Observable<any> {
    return this.http.post(`${myGlobals._apiPathv3}prescreen-forms/category/create`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    getPrescreenFormCategoryList(): Observable<any> {
        return this.http.get(`${myGlobals._apiPathv3}prescreen-forms/category`, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    PrescreenFormCategoryUpdate(data: any,id:string): Observable<any> {
        return this.http.put(`${myGlobals._apiPathv3}prescreen-forms/category/update/${id}`, data, {headers: this._Global.httpOptions }) as Observable<any>;
    }

    deletePrescreenFormCategory(id:string): Observable<any> {
        return this.http.delete(`${myGlobals._apiPathv3}prescreen-forms/category/delete/${id}`, {headers: this._Global.httpOptions }) as Observable<any>;
    }


}