import { NgModule } from "@angular/core";

import { SearchableDropdownComponent } from "./components/searchable-dropdown/searchable-dropdown.component";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { DropDownListAllModule } from "@syncfusion/ej2-angular-dropdowns";
import { MaterialModule } from "./app.module";
import { GlobalModule } from "./GlobalModule/GlobalModule.module";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    DropDownListAllModule,

    MaterialModule,
    GlobalModule,
    DropDownListAllModule,
  ],
  declarations: [SearchableDropdownComponent],
  exports: [SearchableDropdownComponent],
})
export class SharedModule {}
