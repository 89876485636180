import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { FilteringEventArgs } from "@syncfusion/ej2-dropdowns";

@Component({
  selector: "app-searchable-dropdown",
  templateUrl: "./searchable-dropdown.component.html",
  styleUrls: ["./searchable-dropdown.component.css"],
})
export class SearchableDropdownComponent implements OnInit, OnChanges {
  @Input() control: FormControl;
  @Input() placeholder: string = "All";
  @Input() floatLabelType: string = "Never";
  @Input() dataSource: unknown[];
  @Input() fields: unknown;
  @Input() allowFiltering: boolean = false;
  @Input() addAllOption: boolean = true;

  @Output() filtering: EventEmitter<FilteringEventArgs> = new EventEmitter();
  @Output() change: EventEmitter<void> = new EventEmitter();

  private options: unknown[] = null;

  constructor() {}

  ngOnInit() {
    this.addDefaultOption();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (Object.prototype.hasOwnProperty.call(changes, propName)) {
        if (propName === "dataSource") {
          this.addDefaultOption();
        }
      }
    }
  }

  onChange(): void {
    this.change.emit();
  }

  onFiltering(e: FilteringEventArgs): void {
    this.filtering.emit(e);
  }

  addDefaultOption(): void {
    this.control.setValue(null);
    this.options = [];

    if (this.addAllOption) {
      this.options = [{ Id: -1, Name: this.placeholder }];
    }

    if (this.dataSource) this.options.push(...this.dataSource);
  }
}
