import { DatepickerModule } from './../../components/datepicker/datepicker.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@app/app.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalModule } from '@app/GlobalModule/GlobalModule.module';
import { TemplaterenderComponent } from '@app/aggrid/templaterender/templaterender.component';
import { AgGridModule } from 'ag-grid-angular';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
// import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { BrowserModule } from '@angular/platform-browser';
import { DatatableModule } from '@app/components/datatable/datatable.module';
import { PatientDetailComponent } from './patientdetail.component';
import { MatButtonModule, MatSidenavModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuickScriptrModule } from '@app/shared/quickscript/quick-script.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        DatepickerModule,
        GlobalModule,
        AgGridModule.withComponents([TemplaterenderComponent]),
        CheckBoxModule,
        DropDownListAllModule,
        DatePickerAllModule,
        GridAllModule,
        NumericTextBoxAllModule,
        ToolbarModule,
        DatatableModule,
        MatSidenavModule, MatButtonModule,
        MatSidenavModule,
        QuickScriptrModule
    ],
    declarations: [PatientDetailComponent],
    exports: [PatientDetailComponent],
    entryComponents: [PatientDetailComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PatientDetailModule { }